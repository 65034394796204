import React from "react";

import { styles } from "../../constant";
import "../../style/InfoCard.css";

export default function InfoCard(props) {
  // console.log("props?", props);
  const propsImages = props?.info?.imageUrl.toString();
  return (
    <div className="infocard_div">
      <div style={{ display: "flex", justifyContent: "center",height:80 , backgroundColor:""}}>
        <img
          className="info_icon"
          src={require(`../../assets/${props?.info?.imageUrl}.svg`)}
          alt="data"
          height={110}
          width="80%"
        />
      </div>
      <div>
        <p
          className="infoCard_text"
          style={{
            fontFamily: styles.fonts,
            lineHeight: 1.3,
            textAlign: "center",
            
          }}
          dangerouslySetInnerHTML={{ __html: props?.info?.text }}
        />
        {/* <p
          className="infoCard_text"
          style={{ fontFamily: styles.fonts, textAlign: "center" }}
        >
          Fun and interactive <br /> story-based learning
        </p> */}
      </div>
    </div>
  );
}
