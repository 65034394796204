import React from "react";
import MediaCard from "../component/Cards/MediaCard";
import { styles } from "../constant";
import "../style/Blog.css";
import { useState, useEffect } from "react";
import { getAPI } from "../api";

export default function Knowledge() {
  const scrollBarStyle = {
    // border: "1px solid red",
    width: "300px",
    height: "300px",
  };
  const [BlogData, setBlogData] = useState();
  React.useEffect(() => {
    const requestData = {
      url: process.env.REACT_APP_BASEURL_API + "V1/blog/list",
    };
    getAPI(requestData)
      .then((res) => {
        // console.log("resData", res);
        setBlogData(res?.data);
      })
      .catch((e) => console.log(e));
  }, []);

  // console.log("resData", BlogData);

  return (
    <div>
      {BlogData && (
        <div className="blog_container">
          <div>
            <p
              className="heading_blog"
              style={{
                fontFamily: styles.fonts,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                height: 10,
                
              }}
            >
              Knowledge is Power!
            </p>
          </div>
          <div className="blog_card">
            <div
              className="blog_card_one"
              style={{ display: "flex", marginRight: "2rem" }}
            >
              <MediaCard
                className="blog_card_one_container"
                dataProps={{
                  id: 1,
                  height: 340,
                  img: BlogData && BlogData?.length > 0 && BlogData[0].image,
                  heading:
                    BlogData && BlogData?.length > 0 && BlogData[0].title,
                  subHeader: 
                    BlogData && BlogData?.length > 0 && BlogData[0].description,

                  publish:
                    BlogData &&
                    BlogData?.length > 0 &&
                    BlogData[0].publish_date,
                  time: BlogData && BlogData?.length > 0 && BlogData[0].tag,
                }}
              />
            </div>

            <div className="blog_card_container">
              <div className="blog_card_second">
                <MediaCard
                  dataProps={{
                    id: 2,
                    height: 300,
                    img: BlogData && BlogData?.length > 0 && BlogData[1].image,

                    heading:
                      BlogData && BlogData?.length > 0 && BlogData[1].title,
                    subHeader:
                      BlogData &&
                      BlogData?.length > 0 &&
                      BlogData[1].description,
                    publish:
                      BlogData &&
                      BlogData?.length > 0 &&
                      BlogData[1].publish_date,
                    time: BlogData && BlogData?.length > 1 && BlogData[1].tag,
                  }}
                />
              </div>
              <div>
                <MediaCard
                  dataProps={{
                    id: 3,
                    height: 300,
                    img: BlogData && BlogData?.length > 0 && BlogData[2].image,
                    heading:
                      BlogData && BlogData?.length > 0 && BlogData[2].title,
                    subHeader:
                      BlogData &&
                      BlogData?.length > 0 &&
                      BlogData[2].description,
                    publish:
                      BlogData &&
                      BlogData?.length > 0 &&
                      BlogData[2].publish_date,
                    time: BlogData && BlogData?.length > 0 && BlogData[2].tag,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
