import React from "react";
import "../../style/Footer.css";
import logo from "../../assets/unition-logo.png";
import logo2 from "../../assets/logo2.svg";
import FaceBook from "../../assets/facebook.png";
import { styles } from "../../constant";
import Linkdin from "../../assets/linkdin.png";
import Instagram from "../../assets/instagram.png";
import Twitter from "../../assets/twitter.png";
import { Link } from "react-router-dom";
import ScrollImg from "../../assets/upScroll.png";

const Footer = () => {
  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div className="footer">
      <div className="mainBox">
        <div>
          <img src={logo2} alt="logo" height={75} width={180} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="footer_icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ cursor: "pointer" }}>
              <img
                src={FaceBook}
                alt="logo"
                height={70}
                width={70}

                onClick={() =>
                  window.open("https://www.facebook.com/people/Unition-Academy/100087092492317/", "_blank")
                }
              />
            </div>
            <div style={{ cursor: "pointer" }}>
              <img
                src={Instagram}
                alt="logo"
                height={70}
                width={70}
                onClick={() =>
                  window.open("https://www.instagram.com/unition_academy/", "_blank")
                }
              />
            </div>
            <div style={{ cursor: "pointer" }}>
              <img
                src={Linkdin}
                alt="logo"
                height={70}
                width={70}
                onClick={() =>

                  window.open("https://www.linkedin.com/company/unition-academy/", "_blank")
                }
              />
            </div>
            {/* <div style={{ cursor: "pointer" }}>
              <img
                src={Twitter}
                alt="logo"
                height={70}
                width={70}
                // onClick={() =>
                //   window.open("https://www.linkedin.com/login/", "_blank")
                // }
              />
            </div> */}
          </div>
          <div>
            <p style={{ fontFamily: styles.fonts }} className="fontsFooter">
              © 2022 Worldling Apex Pvt. Ltd. All rights reserved.
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          onClick={topFunction}
          className="myBtnScroll"
          title="Go to top"
          style={{ fontFamily: styles.fonts }}
        >
          <img src={ScrollImg} alt="scroll" className="upScrollImg" />
        </button>
      </div>
    </div>
  );
};

export default Footer;
