import React from "react";
import { Box } from "@mui/material";
import "./../style/info.css";
import { styles } from "../constant";
import InfoCard from "../component/Cards/InfoCard";

import Button from "@mui/material/Button";

import { Link } from "react-scroll";
import { getAPI } from "../api";

export default function Information() {
  const CardInfo = [
    {
      imageUrl: "infoOne",
      text: "Fun and interactive </br> story-based learning",
    },
    {
      imageUrl: "infoTwo",
      text: "Tangible results through </br>  timely assessments",
    },
    {
      imageUrl: "infoThree",
      text: "Safe space social media </br>  for children ",
    },
    {
      imageUrl: "infofour",
      text: "Interactive community for  </br> parents assisted by </br> professionals",
    },
  ];

  const [HomePageData, setHomePageData] = React.useState();
  React.useEffect(() => {
    const requestData = {
      url: process.env.REACT_APP_BASEURL_API + "V1/user_home/details",
    };
    // setLoader(true);
    getAPI(requestData)
      .then((res) => {
        setHomePageData(res.data[0]);
        // setLoader(false);
      })
      .catch((e) => console.log(e));
  }, []);
  return (
    // <div style={{ backgroundColor:"red" }}>

    <div
      style={{
        display: "flex",
        justifyContent: "center ",
        paddingInline: "20px",
        // backgroundColor:"red"
      }}
      id="information"
    >
      {HomePageData && (
        <Box
          sx={{
            width: "100%",
            height: 500,
            borderRadius: 10,
            backgroundColor: "#142A59",
            "@media screen and (max-width: 767px)": {
              height: "fit-content",
            },
            // "&:hover": {
            //   backgroundColor: "primary.main",
            //   opacity: [0.9, 0.8, 0.7],
            // },
          }}
        >
          <div>
            <div>
              <h1
                className="info_heading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: styles.fonts,
                }}
              >
                Curious? <br />
                Know Why Us!
              </h1>
            </div>
          </div>
          <div
            className="info_card"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              // backgroundColor:"red",
              paddingInline:2,
            }}
          >
            {CardInfo &&
              CardInfo?.map((item, key) => <InfoCard info={item} key={key} />)}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <Link
              // activeClass="active"
              to="courses"
              spy={true}
              smooth={true}
              offset={0}
              duration={700}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#EEBB58",
                  color: "#2E2E2E",
                  fontFamily: styles.fonts,
                  borderRadius: 10,
                  fontWeight: 700,
                  textAlign: "center",
                  // marginBottom:10
                }}
              >
                VIEW COURSES
              </Button>
            </Link>
          </div>
        </Box>
      )}
    </div>
  );
}
