import React from "react";
import Courses from "./Courses";
import { styles } from "../constant";
import "../style/Home.css";
import MenImg from "./../assets/men.png";
import RedirectiImg from "./../assets/redirection.png";
import Information from "./Information";
import BrandStory from "./BrandStory";
import Knowledge from "./Blog";
import Contact from "./Contact";
import MiniChatImg from "../assets/miniChat.png";
import CloseImg from "../assets/close.png";
import Home from "./Home";
import RootBackground from "./../assets/HomepageSecond.jpg";
import { getAPI, postAPI } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useState } from "react";
import FormModal from "../component/Modal/FormModal";
import Popup from "reactjs-popup";
import RootPopup from "../component/Popup/FormDialog";
import FormDialog from "../component/Popup/FormDialog";
import ScrollImg from "../assets/upScroll.png";

export default function Root() {
  const [Loader, setLoader] = React.useState(false);
  const [HomePageData, setHomePageData] = React.useState();
  const [Visible, setVisible] = useState(true);
  const [open, setOpen] = React.useState(false);

  const [emailError, setEmailError] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [CommentError, setCommentError] = React.useState(false);
  React.useEffect(() => {
    const requestData = {
      url: process.env.REACT_APP_BASEURL_API + "V1/user_home/details",
    };
    setLoader(true);
    getAPI(requestData)
      .then((res) => {
        // console.log("res?.data",res?.data)
        setHomePageData(res?.data && res?.data[0]);
        setLoader(false);
      })
      .catch((e) => console.log(e));
  }, []);

  const [showBtn, setShowBtn] = useState("myBtn none");

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShowBtn("myBtn");
      setVisible(true);
    } else {
      setShowBtn("none");
    }

    if (
      document.body.scrollTop > 2892 ||
      document.documentElement.scrollTop > 2892
    ) {
      setVisible(false);
      // console.log("value", document.documentElement.scrollTop);
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const handleSubmit = (formData) => {
    // console.log("all click", formData?.suggestion === "");

    var data = new FormData();

    data.append("name", formData?.name);
    data.append("email", formData?.email);
    data.append("comment", formData?.comment);
    data.append(
      "que1_ans",
      formData?.suggestion ? formData?.suggestion : "empty"
    );
    data.append("que2_ans", formData?.issues ? formData?.issues : "empty");
    data.append(
      "que3_ans",
      formData?.nameTwo && formData?.contactEmail
        ? `${formData?.nameTwo} - ${formData?.contactEmail}`
        : "empty"
    );
    data.append(
      "que4_ans",
      formData?.friendName && formData?.contactEmailFriend
        ? `${formData?.friendName} - ${formData?.contactEmailFriend}`
        : "empty"
    );
    data.append("que5_ans", formData?.hearFrom ? formData?.hearFrom : "empty");

    // if (formData.name === "") {
    //   setNameError(true);
    //   // console.log("errpr", FormErrors?.name);
    // } else {
    //   setNameError(false);
    // }
    // if (formData.email === "") {
    //   setEmailError(true);
    // } else {
    //   /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)
    //     ? setEmailError(false)
    //     : setEmailError(true);
    // }

    // if (formData.comment === "") {
    //   setCommentError(true);
    // } else {
    //   setCommentError(false);
    // }

    const requestData = {
      url: process.env.REACT_APP_BASEURL_API + "V1/feedback",
      data: data,
    };

    // console.log("all click", requestData);

    postAPI(requestData)
      .then((res) => {
        // debugger;
        // console.log("resData", res);
        // setBlogData(res?.data);
        setOpen(false);
        // alert("Form Successfully Submitted");
      })
      .catch((e) => console.log(e));
  };

  // console.log("Loader", Loader);
  return (
    <div id="home">
      {Loader ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          {/* <button
            onClick={topFunction}
            id="myBtn"
            className={showBtn}
            title="Go to top"
            style={{ fontFamily: styles.fonts }}
          >
            <img src={ScrollImg} alt="men" className="upScrollImg" />
          </button> */}
          {Visible && (
            <div>
              <input type="checkbox" id="check" />
              <label className="myChatBtn" onClick={() => setOpen(true)}>
                <img
                  src={MiniChatImg}
                  alt="men"
                  height={100}
                  width={40}
                  style={{ marginTop: 10 }}
                />
              </label>
            </div>
          )}
          <div>
            <Home />
          </div>
          <div
            style={{
              backgroundImage: `url(${RootBackground})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div id="courses">
                {HomePageData && <Courses />}
                {/* <FormDialog /> */}
              </div>
            </div>
            <div>
              <div id="information" style={{ padding: "40px 40px" }}>
                {HomePageData && <Information />}
              </div>
            </div>
            <div>
              <div id="brandstory" style={{ padding: "40px 66px" }}>
                <BrandStory />
              </div>
            </div>

            <div>
              <div id="blog" style={{ padding: "40px 30px" }}>
                <Knowledge />
              </div>
            </div>
            <div>
              <div id="contact" style={{ paddingTop: 20 }}>
                {HomePageData && <Contact />}
              </div>

              <FormModal
                handleClick={setOpen}
                value={open}
                onSubmitClick={(data) => handleSubmit(data)}
                errors={{
                  name: nameError,
                  email: emailError,
                  comment: CommentError,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
