import * as React from "react";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import "../../style/contact.css";
// import parse from "html-react-parser";

import { styles } from "../../constant";

export default function ContactCard(props) {
  // console.log("props?.id", props);
  return (
    <div>
      <Box
        sx={{
          width: "28vw",
          height: 300,
          // justifyContent: "flex-end",
          // margin:"5px",
          borderRadius: 3,
          backgroundColor: "#fff",
          marginTop: 0,
          "@media screen and (max-width: 767px)": {
            width: "fit-content",
            marginBottom: "20px",
            height: "fit-content",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 15,
            paddingTop: 20,
            width: "fit-content",
          }}
        >
          <div
            className="contact_Card_img"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="contact_Card_icon"
              src={require(`./../../assets/${props?.dataProps?.img}.png`)}
              alt="img"
              height={70}
              width={80}
            />
          </div>

          <div
            className="contact_boxText"
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 0,
            }}
          >
            <p style={{ fontFamily: styles.fonts }}>
              {props?.dataProps?.heading}
            </p>
          </div>
          {/* <div className="brand_boxPara"> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              // padding: 10,
            }}
          >
            <p
              style={{
                fontFamily: styles.fonts,
                lineHeight: 1.3,
                minHeight: 60,
              }}
              dangerouslySetInnerHTML={{ __html: props?.dataProps?.subHeading }}
            />
          </div>
          {/* </div> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              className="card_viewBtn"
              onClick={() => {
                if (props?.dataProps?.id === 2) {
                  window.open("mailto:hello@unitionacademy.com");
                } else if (props?.dataProps?.id === 1) {

                  window.open(
                    "https://api.whatsapp.com/send?phone=9307471325",
                    "_blank"
                  );
                } else {
                  window.open(`tel:9307471325`);
                }
              }}
              style={{
                borderColor: "#193572",
                color: "#193572",
                display: "flex",
                fontWeight: 700,
                justifyContent: "center",
              }}
            >
              {props?.dataProps?.btnText}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
}
