import React, { useState } from "react";
// import Button from "@material-ui/core/Button";
import Button from "@mui/material/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { postAPI } from "../../api";
import { styles } from "../../constant";
import axios from "axios";

import Slide from "@mui/material/Slide";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fontWeight } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function FormDialog(props) {
  const [Email, setEmail] = useState();
  const [Error, setError] = useState(false);

  // console.log("props",props)

  const handleSubmit = async () => {
    let emailValidation = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(Email);
    // console.log("data",emailValidation);
    /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(Email) ? setError(false) : setError(true);
    // console.log(" !(/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(Email)) &", (/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(Email)))

    !Error &&
      Email !== "" &&
      axios
        .post(
          process.env.REACT_APP_BASEURL_API + "V1/news_letter/create",
          { email: Email.toString() },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          // debugger;

          alert("Thanks for the connecting with us.");
          // props.setHandler(true);
          localStorage.setItem("newsLetterNotSubmitted", false);
          props?.handleClose();
          props?.handleSubmit();
        })
        .catch((err) => {
          alert(
            err.response.data.message
              ? err.response.data.message
              : "Something went wrong"
          );
          // console.log(err.response.data.message);
        });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          style={{
            color: "black",
            fontFamily: "Inter",
            fontWeight: 500,
          }}
        >
          <span style={{ fontFamily: styles.fonts }}>Subscribe</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontFamily: styles.fonts }}>
            We have exciting things coming your way. Subscribe for more
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            // color="rgb(25, 53, 114)"
            style={{ color: "rgb(25, 53, 114)" }}
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email Address"
            type="email"
            error={Error}
            helperText={Error ? "Please enter a valid email address" : ""}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            style={{ color: "rgb(25, 53, 114)", fontFamily: styles.fonts }}
          >
            Skip
          </Button>
          <Button
            onClick={handleSubmit}
            style={{ color: "rgb(25, 53, 114)", fontFamily: styles.fonts }}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
