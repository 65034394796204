// import Carousel from "react-multi-carousel";
// import { Image } from "semantic-ui-react";
import { useState } from "react";
import React from "react";
import demo from "../../assets/demo.png";
import CourseCard from "../Cards/CourseCard";
import FormDialog from "../Popup/FormDialog";
import "../../style/courseSlider.css";
// import Carousel from "react-grid-carousel";
import { useNavigate } from "react-router-dom";
import Carousel from "react-grid-carousel";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import Carousel from "@brainhubeu/react-carousel";
// import "@brainhubeu/react-carousel/lib/style.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    paritialVisibilityGutter: 14,
  },
  laptop: {
    breakpoint: { max: 1024, min: 907 },
    items: 3,
    paritialVisibilityGutter: 26,
  },
  tablet: {
    breakpoint: { max: 907, min: 564 },
    items: 2,
    paritialVisibilityGutter: -20,
  },
  miniTablet: {
    breakpoint: { max: 564, min: 425 },
    items: 2,
    paritialVisibilityGutter: -100,
  },
  mobile: {
    breakpoint: { max: 425, min: 0 },
    items: 2,
    paritialVisibilityGutter: -150,
  },
};

const CourseSlider = ({ deviceType, data }) => {
  let [open, setOpen] = useState(false);
  let [popupHandler, setHandler] = useState(false);
  let [CourseData, setCourseData] = useState();

  const navigate = useNavigate();

  React.useEffect(() => {
    // setHandler(true);
    // debugger;
    let localStorageValue = localStorage.getItem("newsLetterNotSubmitted");

    if (localStorageValue === "true" || localStorageValue === null) {
      localStorage.setItem("newsLetterNotSubmitted", true);
      localStorage.setItem("popHandler", false);
    }
  }, []);

  const ViewDetailHandler = (value, data) => {
    // debugger;
    // console.log("value",value)
    setCourseData(data);
    value ? setOpen(true) : setOpen(false);
  };

  // console.log("Data", CourseData);

  const handleSubmit = () => {

    localStorage.setItem("popHandler", true);
    localStorage.setItem("newsLetterNotSubmitted", false);
    // setHandler(true);
    if (CourseData && CourseData?.chapter.length > 0) {
      navigate("course-detail", {
        state: { courseData: CourseData },
      });
    }
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
      <div className="carousel-button-group">
        <button
          // style={{
          //   marginLeft: -58,

          // }}
          aria-label="Go to previous slide"
          className={
            currentSlide === 0
              ? "disable"
              : "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
          }
          onClick={() => previous()}
        ></button>
        <button
          // style={{ marginRight: -46 }}
          aria-label="Go to next slide"
          className={
            currentSlide === totalItems - slidesToShow
              ? "disable"
              : "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
          }
          onClick={() => next()}
        ></button>
      </div>
    );
  };

  const handleClose = () => {
    // debugger;
    setOpen(false);
    setHandler(true);
    handleSubmit();
  };


  const dataRes = [
    {
      breakpoint: 2560,
      cols: 4,
      rows: 1,
      gap: 0,
      loop: true,
    },
    {
      breakpoint: 1496,
      cols: 4,
      rows: 1,
      gap: 33,
      loop: true,
    },
    {
      breakpoint: 1440,
      cols: 4,
      rows: 1,
      gap: 0,
      loop: true,
    },
    {
      breakpoint: 1396,
      cols: 3,
      rows: 1,
      gap: 0,
      loop: true,
    },
    {
      breakpoint: 1023,
      cols: 2,
      rows: 1,
      gap: 23,
      loop: true,
    },
    {
      breakpoint: 767,
      cols: 1,
      rows: 1,
      gap: 11,
      loop: true,
    },
    {
      breakpoint: 426,
      cols: 1,
      rows: 1,
      gap: 60,
      loop: true,
    },
    {
      breakpoint: 376,
      cols: 1,
      rows: 1,
      gap: 10,
      loop: true,
    },
    {
      breakpoint: 325,
      cols: 1,
      rows: 1,
      gap: 16,
      loop: true,
    },
  ];
  return (
    data && (
      <div>
        {/* <Carousel
          //   swipeable={true}
          //   draggable={true}
          //   showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={true}
          customButtonGroup={<ButtonGroup />}
          autoPlaySpeed={800}
          //   keyBoardControl={true}
          // customTransition="all .5"
          transitionDuration={1000}
          //   containerClass="carousel-container"
          arrows={false}
          // removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={"desktop"}
          //   dotListClass="custom-dot-list-style"
          itemClass="image-item"
          //   ssr={true}
          partialVisible
          //   deviceType={deviceType}
          //   itemClass="image-item"
          //   responsive={responsive}
          //   autoPlay={true}
        >
          {data &&
            data
              ?.slice(0.5)
              .map((item, key) => (
                <CourseCard
                  product={item}
                  key={key}
                  eventHandle={popupHandler}
                  ViewDetailHandler={(value, data) =>
                    ViewDetailHandler(value, data)
                  }
                />
              ))}
        </Carousel> */}
        {/* <Carousel cols={4} rows={1} gap={1} loop={true}>
          {data &&
            data?.slice(0.5).map((item, key) => (
              <Carousel.Item key={key}>
                <CourseCard
                  product={item}
                  key={key}
                  ViewDetailHandler={(value) => ViewDetailHandler(value)}
                />
              </Carousel.Item>
            ))}
        </Carousel> */}

        <Carousel
          // cols={4}
          // rows={1}
          loop
          responsiveLayout={dataRes}
          mobileBreakpoint={0}
        // arrowRight={
        //   <ArrowCircleRightIcon
        //     type="right"
        //     fontSize="large"
        //     sx={{ position: "absolute", top: "220px", right: 0 }}
        //   />
        // }
        // arrowLeft={
        //   <ArrowCircleLeftIcon
        //     type="left"
        //     fontSize="large"
        //     sx={{ position: "absolute", top: "220px", left: 0 }}
        //   />
        // }
        >
          {data &&
            data?.slice(0.5).map((item, key) => (
              <Carousel.Item key={key}>
                <CourseCard
                  product={item}
                  key={key}
                  eventHandle={popupHandler}
                  ViewDetailHandler={(value, data) =>
                    ViewDetailHandler(value, data)
                  }
                />
              </Carousel.Item>
            ))}
        </Carousel>

        <FormDialog
          open={open}
          handleClose={handleClose}
          setHandler={setHandler}
          CourseData={CourseData}
          handleSubmit={handleSubmit}
        />
      </div>
    )

    // <Carousel
    //   ssr={true}
    //   partialVisbile
    //   deviceType={deviceType}
    //   itemClass="image-item"
    //   responsive={responsive}
    //   infinite={true}
    //   autoPlay={true}
    //   autoPlaySpeed={500}
    // >
    //   {data.slice(0, 5).map((item) => {
    //     return <CourseCard product={item} />;
    //   })}
    // </Carousel>
  );
};

export default CourseSlider;
