import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../assets/unition-logo.png";
import { styles } from "../../constant";
import { Link } from "react-scroll";
import "../../style/Header.css";

const drawerWidth = 240;
const navItems = [
  // { label: "", routes: "home" },
  { label: "Our Courses", routes: "courses" },
  { label: "Why Us", routes: "information" },
  { label: "Our Story", routes: "BrandStory" },
  { label: "Blog", routes: "blog" },
  { label: "Contact Us", routes: "contact" },
];

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  let active = false;

  if (location.pathname === "/course-detail") {
    active = true;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to="/">
          <img src={logo} onClick={() => handleLogo()} alt="logo" height={50} width={150} />
        </Link>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, key) => (
          <Link
            // activeClass="none"
            to={item.routes}
            spy={true}
            smooth={true}
            offset={0}
            duration={700}
          // onSetActive={this.handleSetActive}
          >
            <ListItem key={key} disablePadding onClick={handleDrawerToggle}>
              <ListItemButton
                sx={{ textAlign: "center", color: "#193572", fontWeight: 600 }}
              >
                <ListItemText primary={item?.label} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const buttonClick = (value, event) => {
    // console.log("item", value);

    navigate(value?.routes?.toString());
  };

  const handleLogo = () => {
    // debugger;
    navigate("/")
  }
  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <AppBar
        className="nav_bar"
        component="nav"
        position="static"
        sx={{
          background: "transparent",
          position: "absolute",
          padding: "20px 30px 0 30px",
          boxShadow: "none",
          "@media screen and (min-width: 768px) and (max-width: 952px)": {
            padding: "20px 0px 0 0px",
          },
          "@media screen and (max-width: 768px)": {
            padding: "20px 0px 0 0",
          },
        }}
      >
        <Toolbar>

          <Typography
            variant="h6"
            component="div"
            sx={{ display: {} }}
            style={
              {
                // backgroundColor: "#EEBB58",
              }
            }
          >

            <img
              className="main_logo"
              src={logo}
              onClick={() => handleLogo()}
              alt="logo"
              style={{ cursor: "pointer", marginTop: 4 }}
              height={60}
              width={168}
            />

          </Typography>

          <Box
            sx={{
              display: { flexGrow: 1, xs: "none", sm: "block" },
              "@media screen and (max-width: 900px)": {
                justifyContent: "end !important",
              },
            }}
            style={{
              // backgroundColor: "red",
              display: "flex",
              justifyContent: active ? "space-between" : "end",
              alignItems: "baseline",
              // textDecoration: completed ? 'line-through' : 'none'
            }}
          >
            <div
              className="nav-list"
              style={{
                fontFamily: styles.fonts,
                // marginLeft: "30px",
                marginRight: "20px",
                textTransform: "capitalize",
              }}
            >
              {navItems.map((item, key) => (
                <Button
                  key={key}
                  sx={{
                    color: "#2E2E2E",
                    // backgroundColor:"red",
                    marginRight: "10px",
                    "@media screen and (min-width: 768px) and (max-width: 952px)":
                    {
                      marginRight: "0px",
                    },
                    // "@media screen and (max-width: 768px)":
                    // {
                    //   marginRight: "0px",
                    //   paddingRight:"0px"
                    // },
                  }}
                  // onClick={(e) => buttonClick(item, e)}
                  style={{
                    fontFamily: styles.fonts,
                    textTransform: "capitalize",
                  }}
                >
                  <Link
                    activeClass="link"
                    // onSetActive={{backf}}
                    to={item.routes}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={700}

                  // onSetActive={this.handleSetActive}
                  >
                    <p
                      className="nav_p"
                      style={{
                        fontSize: "16px",
                        // backgroundColor: "blue",
                        textTransform: "capitalize",
                      }}
                    >
                      <p
                        style={{
                          // fontFamily: styles.fonts,
                          // fontSize: "16px",
                          // backgroundColor: "blue",

                          textTransform: "capitalize",
                        }}
                      >
                        {item?.label}
                      </p>
                    </p>
                  </Link>
                </Button>
              ))}
            </div>

            <Link
              // activeClass="active"
              to="courses"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            // onSetActive={this.handleSetActive}
            >
              <Button
                className="btn"
                variant="contained"
                style={{
                  backgroundColor: "#EEBB58",
                  color: "#2E2E2E",
                  fontFamily: styles.fonts,
                  borderRadius: 10,
                  // backgroundColor: "blue",
                  // backgroundColor: "aqua",

                  // marginTop: "0px",
                  height: "fit-content",
                  // marginLeft:"650px",

                  fontWeight: 700,
                }}
              >
                TRY NOW!
              </Button>
            </Link>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ color: "#193572", ml: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              // color: "red",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
