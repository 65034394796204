import React, { useState } from "react";
import "../../style/CourseVideo.css";
import videoThumbnail from "../../assets/videolist1.png";
import { styles } from "../../constant";
import StarIcon from "@mui/icons-material/Star";
import { yellow } from "@mui/material/colors";
import ViewImg from "../../assets/view.png";
import ReactStars from "react-rating-stars-component";
// import Modal from "@mui/material/Modal";
import FormModal from "../Modal/FormModal";
import ScrollBars from "react-scrollbar";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import { postAPI } from "../../api";

const CourseVideo = (props) => {
  const courses = {
    course: [
      {
        id: 1,
        title: "Periods",
        desc: " Lorem Ipsum is simply dummy text of the printing and typeset industry.",
        vid: "raxPKklDF2k",
      },
      {
        id: 2,
        title: "Peer Presure",
        desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        vid: "fGoWLWS4-kU",
      },
      {
        id: 3,
        title: "Consent & more...",
        desc: " The module introduces consent and sexuality as a concept  upcoming modules. ",
        vid: "h3nhM9UlJjc",
      },
    ],
  };

  // console.log("cc", props);

  let courseVideoData = props?.courseData?.chapter ? props?.courseData?.chapter : JSON.parse(localStorage.getItem("chapterData"));


  // console.log("courseVideoData", courseVideoData);
  // const [video, setVideo] = useState(courses.course[0].vid);
  // const [title, setTitle] = useState(courses.course[0].title);
  // const [desc, setDesc] = useState(courses.course[0].desc);

  const [open, setOpen] = React.useState(false);

  // console.log("courseVideoData", courseVideoData);

  const [videoCourses, setVideoCourses] = useState({
    videoID: courseVideoData[0]?.video,
    courseTitle: courseVideoData[0]?.name,
    courseDesc: courseVideoData[0]?.long_description,
    courseThumbnail: videoThumbnail,
    courseShortDesc: courseVideoData[0]?.short_description,
    courseCreated: courseVideoData[0]?.created_at,
    courseUpdated: courseVideoData[0]?.updated_at,
    course_id: courseVideoData[0]?.course_id,
  });
  const [Viewed, setViewIcon] = useState(
    JSON.parse(localStorage.getItem("videosViews"))
      ? JSON.parse(localStorage.getItem("videosViews"))
      : [courseVideoData[0]]
  );
  const [emailError, setEmailError] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [CommentError, setCommentError] = React.useState(false);

  const [counter, setCounter] = useState(0);
  // const [CourseData, setCourseData] = useState();

  // var arrayLatest = [...Viewed];

  React.useEffect(() => {

    localStorage.setItem("chapterData", JSON.stringify(courseVideoData))

  }, []);
  React.useEffect(() => {
    let localStorageValue = localStorage.getItem("videosViews");

    localStorage.setItem("videosViews", JSON.stringify(Viewed));
    // localStorage.setItem("chapterData", courseVideoData)

    // let arrayfirst = [];
    // arrayfirst.push(courseVideoData[0]);
    // setViewIcon(arrayfirst);
  }, [Viewed]);

  // console.log("videoCourses", Viewed);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const videoClickHandler = (item, index) => {
    // arrayLatest.push(item);
    // let viewArray = [];
    // setVideo(item.vid);
    // setTitle(item.title);
    // setDesc(item.desc);
    // console.log("item", item);
    setVideoCourses({
      videoID: item?.video,
      courseTitle: item?.name,
      courseDesc: item?.long_description,
      courseThumbnail: videoThumbnail,
      courseShortDesc: item?.short_description,
      courseCreated: item?.created_at,
      courseUpdated: item?.updated_at,
      course_id: item?.course_id,
    });
    setCounter(index);

    let viewArray = UserVideos?.find((el) => el.id === item.id);
    // console.log("viewArray", viewArray);
    // // console.log("UserVideos", UserVideos);
    // console.log("item", item);
    // console.log("arrayLatest1", arrayLatest);

    viewArray === undefined && setViewIcon([...Viewed, item]);

    // setViewIcon(array);

    // viewArray !== undefined && setViewIcon([...Viewed, item]);
  };
  // console.log("array", Viewed);
  // console.log("Vie", Viewed);
  const firstExample = {
    size: 30,
    value: 4,
    edit: true,
    onChange: (newValue) => {
      // console.log(`Example 2: new value is ${newValue}`);
      setOpen(true);
    },
  };
  // const scrollBarStyle = {
  //   // border: "1px solid red",
  //   // width: "300px",
  //   height: "400px",
  // };

  var UserVideos = JSON.parse(localStorage.getItem("videosViews"));
  // console.log("UserVideos", UserVideos);

  const handleSubmit = async (formData) => {
    var data = new FormData();
    data.append("name", formData?.name);
    data.append("email", formData?.email);
    data.append("comment", formData?.comment);
    data.append("que1_ans", formData?.suggestion);
    data.append("que2_ans", formData?.issues);
    data.append("que3_ans", `${formData?.nameTwo} - ${formData?.contactEmail}`);
    data.append(
      "que4_ans",
      `${formData?.friendName} - ${formData?.contactEmailFriend}`
    );
    data.append("que5_ans", formData?.hearFrom);

    if (formData.name === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (formData.email === "") {
      setEmailError(true);
    } else {
      /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)
        ? setEmailError(false)
        : setEmailError(true);

      // setEmailError(email);
    }

    if (formData.comment === "") {
      setCommentError(true);
    } else {
      setCommentError(false);
    }
    // if (formData.email === "" && formData.name === "") {
    //   setFormErrors({ name: true, email: true });
    // } else {
    //   setFormErrors({ name: false, email: false });
    // }

    const requestData = {
      url: process.env.REACT_APP_BASEURL_API + "V1/feedback",
      data: data,
    };
    // debugger;

    if (
      formData.name !== "" &&
      formData.email !== "" &&
      /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email) &&
      formData.comment !== ""
    ) {
      await postAPI(requestData)
        .then((res) => {
          // console.log("resData", res);
          // setBlogData(res?.data);
          setOpen(false);
          alert("Form Successfully Submitted");
        })
        .catch((e) => console.log(e));
    }
  };

  const renderVideo = () => {
    return (
      <>
        <div className="video" style={{ width: "100%" }}>
          <div className="video-responsive" style={{ cursor: "pointer" }}>
            <iframe
              title="video"
              // width="1053px"
              // height="1080px"
              src={videoCourses?.videoID}
              autoPlay={true}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="course_bottom_details">
            <div className="course_btm_title">
              <div
                className="courseTitle_details"
                style={{ fontFamily: styles.fonts }}
              >
                {videoCourses?.courseTitle}
              </div>
              <div className="video_feedback">
                <ReactStars {...firstExample} />
              </div>
            </div>
            <p
              style={{
                fontFamily: styles.fonts,
              }}
              className="courseDescription"
            >
              {videoCourses?.courseDesc}
            </p>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="course_video">
      {renderVideo()}
      <div className="side_video_content">
        <h2
          style={{
            // marginLeft: "20px",
            // color: "#193572",
            fontFamily: styles.fonts,
          }}
          className="title_content"
        >
          Course Content
        </h2>
        <hr />
        <div className="side_video">
          <ScrollBars
            horizontal
            autoHide={false}
            className="scroll_bar_container"
          >
            {courseVideoData &&
              courseVideoData?.map((item, index) => {
                let result =
                  UserVideos &&
                    UserVideos?.filter((el) => el.id === item.id).length > 0
                    ? true
                    : false;

                // console.log("grouped", result);

                return (
                  <div
                    key={item.vID}
                    className={
                      counter === index ? "video_list active" : "video_list"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => videoClickHandler(item, index)}
                  >
                    <img src={item?.thumb} alt="img" className="video_img" />
                    {/* {console.log("item?.thumb", item?.thumb)} */}

                    {result && (
                      <img
                        height={20}
                        width={20}
                        src={ViewImg}
                        alt="img"
                        className="check_mark"
                      />
                    )}

                    <div className="video_list_content">
                      <div
                        className="video_list_title"
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#193572",
                          fontFamily: styles.fonts,
                        }}
                      >
                        {item?.name}
                      </div>
                      <div
                        className="video_list_desc"
                        style={{
                          marginTop: "8px",
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: styles.fonts,
                        }}
                      >
                        {item?.short_description}
                      </div>
                    </div>
                  </div>
                );
              })}

            <FormModal
              handleClick={setOpen}
              value={open}
              onSubmitClick={(data) => handleSubmit(data)}
              errors={{
                name: nameError,
                email: emailError,
                comment: CommentError,
              }}
            />
            {/* <div className="video">d</div>
        <div className="video">d</div>
        <div className="video">d</div> */}
          </ScrollBars>
        </div>
      </div>
    </div>
  );
};

export default CourseVideo;
