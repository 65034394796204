import React, { useState } from "react";
import CourseCard from "../component/Cards/CourseCard";
import { styles } from "../constant";
import "./../style/courses.css";

import { getAPI } from "../api";
import demo from "../assets/demo.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CourseSlider from "../component/Courses/CourseSlider";
import FormModal from "../component/Modal/FormModal";
import CarouselSliderTwo from "../component/Courses/CarouselSliderTwo";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function Courses() {
  const cardData = [
    {
      image: demo,
      header: "Parenting: An Eye opener?",
      sub_header:
        "Curated to assist parents to get a deeper understanding of their kids and build a strong relationship.",
      time: "For 8yrs+",
      videoList: [
        {
          id: 1,
          title: "Periods",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 4,
          vID: "raxPKklDF2k",
        },
        {
          id: 2,
          title: "Peer Presure",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "fGoWLWS4-kU",
        },

        {
          id: 3,
          title: "Consent & more...",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "h3nhM9UlJjc",
        },
        {
          id: 3,
          title: "Learning & more...",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "h3nhM9UlJjc",
        },
      ],
    },
    {
      image: demo,
      header: "Money: The Beginning and End",
      sub_header:
        "Introduction to money to attain early financial freedom. Curated to make them independent & generate wealth.",
      time: "For 8yrs+",
      videoList: [
        {
          id: 1,
          title: "Periods",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 4,
          vID: "raxPKklDF2k",
        },
        {
          id: 2,
          title: "Peer Presure",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "fGoWLWS4-kU",
        },

        {
          id: 3,
          title: "Consent & more...",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "h3nhM9UlJjc",
        },
      ],
    },
    {
      image: demo,
      header: "Sexuality Education and Consent",
      sub_header:
        "This course is the perfect opportunity to educate your children about sexuality – in an age-appropriate way.",
      time: "For 8yrs+",
      videoList: [
        {
          id: 1,
          title: "Periods",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 4,
          vID: "raxPKklDF2k",
        },
        {
          id: 2,

          title: "Peer Presure",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "fGoWLWS4-kU",
        },

        {
          id: 3,
          title: "Consent & more...",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "h3nhM9UlJjc",
        },
      ],
    },
    {
      image: demo,
      header: "Emotional Intelligence and Self Awareness",
      sub_header:
        "Let us embark on a journey of understanding the nine base emotions and the concept of SELF.",
      time: "For 8yrs+",
      videoList: [
        {
          id: 1,
          title: "Periods",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 4,
          vID: "raxPKklDF2k",
        },
        {
          id: 2,
          title: "Peer Presure",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "fGoWLWS4-kU",
        },

        {
          id: 3,
          title: "Consent & more...",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "h3nhM9UlJjc",
        },
      ],
    },
    {
      image: demo,
      header: "Professional Self Awareness",
      sub_header:
        "Let us embark on a journey of understanding the nine base emotions and the concept of SELF.",
      time: "For 8yrs+",
      videoList: [
        {
          id: 1,
          title: "Periods",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 4,
          vID: "raxPKklDF2k",
        },
        {
          id: 2,
          title: "Peer Presure",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "fGoWLWS4-kU",
        },

        {
          id: 3,
          title: "Consent & more...",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typeset industry.",
          rating: 3,
          vID: "h3nhM9UlJjc",
        },
      ],
    },
  ];

  const [CourseData, setCourseData] = useState();

  const requestData = {
    url: process.env.REACT_APP_BASEURL_API + "V1/course/list",
  };

  React.useEffect(() => {
    getAPI(requestData)
      .then((res) => {
        // console.log("resData", res);
        setCourseData(res?.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const database = [
    {
      adult: false,
      backdrop_path: "/bQXAqRx2Fgc46uCVWgoPz5L5Dtr.jpg",
      genre_ids: [28, 14, 878],
      id: 436270,
      original_language: "en",
      original_title: "Black Adam",
      overview:
        "Nearly 5,000 years after he was bestowed with the almighty powers of the Egyptian gods—and imprisoned just as quickly—Black Adam is freed from his earthly tomb, ready to unleash his unique form of justice on the modern world.",
      popularity: 17030.072,
      poster_path: "/pFlaoHTZeyNkG83vxsAJiGzfSsa.jpg",
      release_date: "2022-10-19",
      title: "Black Adam",
      video: false,
      vote_average: 7.3,
      vote_count: 1996,
    },
    {
      adult: false,
      backdrop_path: "/tlin6STxxVoln0f818sEQYH7PyC.jpg",
      genre_ids: [27, 53],
      id: 663712,
      original_language: "en",
      original_title: "Terrifier 2",
      overview:
        "After being resurrected by a sinister entity, Art the Clown returns to Miles County where he must hunt down and destroy a teenage girl and her younger brother on Halloween night.  As the body count rises, the siblings fight to stay alive while uncovering the true nature of Art's evil intent.",
      popularity: 1352.026,
      poster_path: "/8gLhu8UFPZfH2Hv11JhTZkb9CVl.jpg",
      release_date: "2022-10-06",
      title: "Terrifier 2",
      video: false,
      vote_average: 7,
      vote_count: 673,
    },

    {
      adult: false,
      backdrop_path: "/giveZjQGE4Pn3kJvsTn7uHqLjZS.jpg",
      genre_ids: [18],
      id: 615952,
      original_language: "en",
      original_title: "Armageddon Time",
      overview:
        "In 1980, Queens, New York, a young Jewish boy befriends a rebellious African-American classmate to the disapproval of his privileged family and begins to reckon with growing up in a world of inequality and prejudice.",
      popularity: 153.622,
      poster_path: "/2bVjSEaLsUxUZ4Dutz9qRF5zXyd.jpg",
      release_date: "2022-10-28",
      title: "Armageddon Time",
      video: false,
      vote_average: 6.9,
      vote_count: 84,
    },
    {
      adult: false,
      backdrop_path: "/qzbsjUzN88CiSHemJXhKvHDS04B.jpg",
      genre_ids: [53, 18],
      id: 842934,
      original_language: "en",
      original_title: "Poker Face",
      overview:
        "A yearly high-stakes poker game between childhood friends turns into chaos when the tech billionaire host unveils an elaborate scheme to seek revenge for the ways they've betrayed him over the years. But as his plans unfold, a group of thieves hatch plans of their own breaking into the mansion thinking it is empty. The old friends quickly band together and the years of playing the game help them win their way through a night of terror.",
      popularity: 101.726,
      poster_path: "/8a2MIBV9LuPYbF5rOEAohjjMz9P.jpg",
      release_date: "2022-11-16",
      title: "Poker Face",
      video: false,
      vote_average: 6.1,
      vote_count: 35,
    },
    {
      adult: false,
      backdrop_path: "/atmII0hn3iQe3IWMBmIb3cc8EJZ.jpg",
      genre_ids: [10749, 18, 27],
      id: 791177,
      original_language: "en",
      original_title: "Bones and All",
      overview:
        "Abandoned by her father, a young woman embarks on a thousand-mile odyssey through the backroads of America where she meets a disenfranchised drifter. But despite their best efforts, all roads lead back to their terrifying pasts and to a final stand that will determine whether their love can survive their otherness.",
      popularity: 100.099,
      poster_path: "/dBQuk2LkHjrDsSjueirPQg96GCc.jpg",
      release_date: "2022-11-18",
      title: "Bones and All",
      video: false,
      vote_average: 7.4,
      vote_count: 91,
    },
  ];

  // console.log("CourseData", CourseData);
  const IMAGE_URL = "https://image.tmdb.org/t/p/";
  const upcomingMoviesImages = database.map((imageResult, index) => {
    return (
      <img
        key={index}
        src={`${IMAGE_URL}w185${imageResult.poster_path}`}
        alt="Upcoming Movie Posters"
      />
    );
  });

  return (
    <div id="courses">
      {CourseData && (
        <div
          style={{
            display: "flex",
            // flexDirection: "row",
            // justifyContent: "space-around",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div>
              <p
                className="heading_course"
                style={{
                  fontFamily: styles.fonts,

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  // height: 10,
                }}
              >
                Our Courses
              </p>
            </div>
            <div style={{ display:"flex",justifyContent:"center", }}>
              <p
                className="course_para"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  marginBottom: 51,
                  marginTop: 0,
                  fontFamily: styles.fonts,
                }}
              >
                Bridging the gap between the education system and the real world
                by providing small bite sized pieces of life-oriented knowledge
              </p>
            </div>

            <div
              className="course_slider_container"
              style={{
                // display: "flex",
                // flexWrap: "wrap",
                // flexDirection: "row",
                // justifyContent: "center",
                textAlign:"-webkit-center",
                // padding: "21px",
                // paddingLeft:30,
                // paddingRight:28,
                marginBottom: 35,
                // backgroundColor:"red"
              }}
            >
              <CourseSlider data={CourseData} />
              {/* <CarouselSliderTwo image={upcomingMoviesImages}/> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
