import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import Header from "../component/Header/Header";
import Courses from "../pages/Courses";
import Contact from "../pages/Contact";
import Information from "../pages/Information";
import BrandStory from "../pages/BrandStory";
import Blog from "../pages/Blog";
import Footer from "../component/Footer/Footer";
import CourseDetails from "../pages/CourseDetails";
import Root from "../pages/Root";
import { getAPI } from "../api";
import Test from "../pages/Test";
export default function Routing() {
  const [HomePageData, setHomePageData] = React.useState();

  const requestData = {
    url: process.env.REACT_APP_BASEURL_API + "V1/user_home/details",
  };

  React.useEffect(() => {
    getAPI(requestData)
      .then((res) => {
        setHomePageData(res?.data && res?.data[0]);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <BrowserRouter>
      <div>
        
        {HomePageData && <Header />}
        <Routes>
          <Route path="/" element={<Root />} />
          <Route path="home" element={<Home />} />
          <Route path="courses" element={<Courses />} />
          <Route path="contact" element={<Contact />} />
          <Route path="information" element={<Information />} />
          <Route path="story" element={<BrandStory />} />
          <Route path="blog" element={<Blog />} />
          <Route path="course-detail" element={<CourseDetails />} />
          {/* <Route path="test" element={<Test />} /> */}
        </Routes>
        {HomePageData && <Footer />}
      </div>
    </BrowserRouter>
  );
}
