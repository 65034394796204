import React from "react";
import ContactCard from "../component/Cards/ContactCard";
import { styles } from "../constant";
import "../style/contact.css";
import { getAPI } from "../api";

export default function Contact() {
  const requestData = {
    url: process.env.REACT_APP_BASEURL_API + "V1/user_home/details",
  };
  const [HomePageData, setHomePageData] = React.useState();

  React.useEffect(() => {
    getAPI(requestData)
      .then((res) => {
        setHomePageData(res?.data && res?.data[0]);
      })
      .catch((e) => console.log(e));
  }, []);
  return (
    <div>
      {HomePageData && (
        <div className="contact_container">
          <div>
            <span
              className="contact_heading"
              style={{
                fontFamily: styles.fonts,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                marginTop: "50px",

                // height:0
              }}
            >
              Contact Us
            </span>
          </div>
          <div className="contact_card_container">
            <div>
              <ContactCard
                dataProps={{
                  id: 1,
                  img: "chat",
                  heading: "Chat with us",
                  subHeading:
                    "We’ve got live social experts waiting to help \n you <b>Monday to Friday from 9am to 5pm IST</b>",
                  btnText: "CHAT NOW",
                }}
              />
            </div>
            <div>
              <ContactCard
                dataProps={{
                  id: 2,
                  img: "email",
                  heading: "Email Us",
                  subHeading:
                    "Something on your mind? Reach out to us at <b>hello@unitionacademy.com</b>",
                  btnText: "SEND EMAIL",
                }}
              />
            </div>
            <div>
              <ContactCard
                dataProps={{
                  id: 3,
                  img: "mobile",
                  heading: "Give us a call",
                  subHeading:
                    "Give us a ring. Our experts are standing by \n <b>Monday to Friday from 9am to 5pm IST.</b>",
                  btnText: "+91 9307471325",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
