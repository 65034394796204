import * as React from "react";

import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  withStyles,
} from "@material-ui/core";
import { styles } from "../../constant";
import RedirectiImg from "./../../assets/redirectSecond.png";

import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";
import { Dailog } from "@material-ui/core";
import ScrollBars from "react-scrollbar";
import "../../style/Blog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BlogDialog(props) {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(0),
    },
  }));
  const useStyles = makeStyles({
    root: {
      maxWidth: "1700px", // arbitrary value
      maxHeight: "1900px",
      padding: "0px",
      width: "auto",
      borderRadius: "34px",
    },
  });
  const classes = useStyles();

  return (
    <BootstrapDialog
      maxWidth="sm"
      style={{ borderRadius: 50 }}
      customMaxWidth={300}
      TransitionComponent={Transition}
      fullWidth
      open={props?.open}
      onClose={props?.handleClose}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogContent
        style={{
          borderRadius: 8,
          paddingTop: 0,
        }}
      >
        <Card
          sx={{
            borderRadius: 8,
            borderWidth: 0,
          }}
        >
          <div
            className="blog_pop"
            style={{
              display: "flex",
              flexDirection: "column",
              // backgroundColor: "pink",
            }}
          >
            {/* <div
              className="blog_pop_container"
              style={{
                width: 700,
                height: 250,
                // backgroundColor: "green",
              }}
            >
              <CardMedia
                className="blog_card_img"
                component="img"
                height={240}
                image={props?.blogData?.img}
                alt="green iguana"
              />
            </div> */}
            <div className="blog_card_content" style={{ width: 700 }}>
              <CardContent>
                <div
                  className="pop_content"
                  style={{
                    marginLeft: 20,
                    // backgroundColor: "green",
                  }}
                >
                  <Button
                    className="cls_icon"
                    color="variant"
                    style={{
                      position: "relative",
                      // left: "380px",
                      float: "right",
                      // backgroundColor: "green",
                      // zIndex:"99"
                    }}
                  //   onClick={handleCloseForm}
                  >
                    <CloseIcon />
                  </Button>
                  <Typography gutterBottom variant="h5" component="div">
                    <p className="tip" style={{ fontFamily: styles.fonts }}>
                      {props?.blogData?.time}
                    </p>

                    <span
                      className="pop_card_title"
                      style={{
                        fontFamily: styles.fonts,
                        fontWeight: 900,
                        color: "#193572",
                        fontSize: "36px",
                      }}
                    >
                      {props?.blogData?.heading}
                    </span>
                  </Typography>
                  <Typography variant="body2" color="#393939">
                    <span
                      className="pop_card_publish"
                      style={{
                        fontFamily: styles.fonts,
                        fontSize: "18px",
                        color: "#393939",
                        opacity: 0.4,
                        fontWeight: 400,
                      }}
                    >
                      {props?.blogData?.publish}
                    </span>
                  </Typography>
                 
                  <div
                    style={{
                      marginTop: 15,
                      // backgroundColor: "green",
                      height: 240, overflow: 'auto'
                    }}
                  >
                    {/* <ScrollBars horizontal autoHide={false} style={{
                      // border: "1px solid red",
                      // width: "300px",
                      height: "110px",
                    }}> */}
                      <div
                        className="pop_card_desc"
                        style={{
                          paddingRight: "30px",
                          fontFamily: styles.fonts,
                        }}
                      >
                        <span style={{ fontFamily: styles.fonts }}>
                        {props?.blogData?.subHeader}
                        {/* {props?.blogData?.subHeader.length > 250
                          ? props?.blogData?.subHeader.slice(0, 250) + "..."
                          : props?.blogData?.subHeader} */}
                          </span>
                      </div>
                    {/* </ScrollBars> */}
                  </div>
                </div>
              </CardContent>
            </div>
          </div>
        </Card>
      </DialogContent >
    </BootstrapDialog >
  );
}
