// import logo from './logo.svg';
import "./style/App.css";

import Routing from "./routing/Routing";

function App() {
  return (
    <div className="app">
      <Routing />
    </div>
  );
}

export default App;
