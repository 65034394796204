import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styles } from "../../constant";
import { TextField } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import Textarea from '@mui/joy/Textarea';

import "../../style/formModel.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height: 400,

  p: 4,

  "@media screen and (max-width: 525px)": {
    width: 350,
    p: 1,
    paddingTop: "40px",
  },
  "@media screen and (max-width: 425px)": {
    width: 250,
  },
};

export default function FormModal(props) {
  const handleClose = () => props?.handleClick(false);

  const [FormData, setFormData] = React.useState({
    name: "empty",
    email: "empty",
    comment: "empty",
    suggestion: "",
    issues: "",
    nameTwo: "",
    contactEmail: "",
    friendName: "",
    contactEmailFriend: "",
    hearFrom: "FriendFamily",
  });

  // console.log("props", FormData);

  function handleChange(evt) {
    const value = evt.target.value;
    setFormData({
      ...FormData,
      [evt.target.name]: value,
    });
  }

  const handleSubmitMain = () => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    document
      .getElementById("divScroll")
      .scrollIntoView({ behavior: "smooth", block: "start" });
    props?.onSubmitClick(FormData);
  };

  // console.log("FormData", FormData);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={props?.value}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ overflowY: "scroll", }}>
          <div
            style={{
              position: "absolute",
              // float: "right",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
            id="divScroll"
          >
            <CloseIcon />
          </div>

          <div className="text-center">
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                // paddingTop: 10,
                fontFamily: styles.fonts,
                textAlign: "center",
                color: "#193572",
              }}
            >
              Please fill out the form we will contact you soon!
            </span>
          </div>

          <div
            className="chat-form"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* <div>
              <TextField
                className="text_field"
                label="Name"
                name="name"
                type="search"
                variant="standard"
                value={FormData.name}
                onChange={(e) => handleChange(e)}
                error={props.errors && props?.errors.name}
                helperText={
                  props?.errors.name && props?.errors.name
                    ? "Please enter name"
                    : " "
                }
                // InputLabelProps={"Email"}
                style={{ marginTop: 10, width: 360 }}
                sx={{
                  "@media screen and (max-width: 400px)": {
                    width: "auto !important",
                    backgroundColor: "red !important",
                  },
                }}
              />
            </div>
            <div>
              <TextField
                className="text_field"
                label="Email"
                name="email"
                value={FormData.email}
                type="search"
                error={props.errors && props?.errors.email}
                helperText={
                  props?.errors.email && props?.errors.email
                    ? "Please enter a valid email address"
                    : " "
                }
                onChange={(e) => handleChange(e)}
                variant="standard"
                style={{ marginTop: 10, width: 360 }}
              />
            </div>
            <div>
              <TextField
                className="text_field"
                id="outlined-multiline-static"
                label="Comments"
                name="comment"
                value={FormData.comment}
                variant="standard"
                onChange={(e) => handleChange(e)}
                multiline
                error={props.errors && props?.errors.comment}
                helperText={
                  props?.errors.comment && props?.errors.comment
                    ? "Please enter comment"
                    : " "
                }
                rows={5}
                style={{ marginTop: 10, width: 360 }}
                // variant="outlined"
              />
            </div> */}

            <div>
              <FormLabel
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  color: "#193572",
                  fontFamily: styles.fonts,
                  marginTop: 15,
                }}
              >
                Any suggestions for us? It could be in any aspect - our website,
                our content, our approach; anything, we are all ears.
              </FormLabel>
              <TextField
                // className="text_field"
                id="outlined-multiline-static"
                label="Suggestions"
                name="suggestion"
                value={FormData.suggestion}
                variant="standard"
                onChange={(e) => handleChange(e)}
                multiline
                // error={props.errors && props?.errors.comment}
                // helperText={
                //   props?.errors.comment && props?.errors.comment
                //     ? "Please enter comment"
                //     : " "
                // }
                rows={5}
                style={{ marginTop: 10, width: 360 }}
                // variant="outlined"
              />
            </div>

            <div>
              <FormLabel
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  color: "#193572",
                  fontFamily: styles.fonts,
                  marginTop: 15,
                }}
              >
                Are you facing any issues parenting your children? Any topics
                you would like us to address next ?
              </FormLabel>
              <TextField
                // className="text_field"
                id="outlined-multiline-static"
                label="Issues"
                name="issues"
                value={FormData.issues}
                variant="standard"
                onChange={(e) => handleChange(e)}
                multiline
                // error={props.errors && props?.errors.comment}
                // helperText={
                //   props?.errors.comment && props?.errors.comment
                //     ? "Please enter comment"
                //     : " "
                // }
                rows={5}
                style={{ marginTop: 10, width: 360 }}
                // variant="outlined"
              />
            </div>

            <div>
              <FormLabel
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  color: "#193572",
                  fontFamily: styles.fonts,
                  marginTop: 15,
                }}
              >
                Your name and email id please? We promise we won’t spam!😄
              </FormLabel>
              <TextField
                className="text_field"
                label="Name"
                name="nameTwo"
                type="search"
                variant="standard"
                value={FormData.nameTwo}
                onChange={(e) => handleChange(e)}
                // error={props.errors && props?.errors.name}
                // helperText={
                //   props?.errors.name && props?.errors.name
                //     ? "Please enter name"
                //     : " "
                // }
                // InputLabelProps={"Email"}
                style={{ marginTop: 10, width: 360 }}
                sx={{
                  "@media screen and (max-width: 400px)": {
                    width: "auto !important",
                    backgroundColor: "red !important",
                  },
                }}
              />
              <TextField
                className="text_field"
                label="Contact number/Email ID"
                name="contactEmail"
                type="search"
                variant="standard"
                value={FormData.contactEmail}
                onChange={(e) => handleChange(e)}
                // error={props.errors && props?.errors.name}
                // helperText={
                //   props?.errors.name && props?.errors.name
                //     ? "Please enter name"
                //     : " "
                // }
                // InputLabelProps={"Email"}
                style={{ marginTop: 10, width: 360 }}
                sx={{
                  "@media screen and (max-width: 400px)": {
                    width: "auto !important",
                    backgroundColor: "red !important",
                  },
                }}
              />
            </div>

            <div>
              <FormLabel
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  color: "#193572",
                  fontFamily: styles.fonts,
                  marginTop: 15,
                }}
              >
                Would you like to share our website with your friends and
                family?We’ll share it with them🤗
              </FormLabel>
              <TextField
                className="text_field"
                label="Name of friend"
                name="friendName"
                type="search"
                variant="standard"
                value={FormData.friendName}
                onChange={(e) => handleChange(e)}
                // error={props.errors && props?.errors.name}
                // helperText={
                //   props?.errors.name && props?.errors.name
                //     ? "Please enter name"
                //     : " "
                // }
                // InputLabelProps={"Email"}
                style={{ marginTop: 10, width: 360 }}
                sx={{
                  "@media screen and (max-width: 400px)": {
                    width: "auto !important",
                    backgroundColor: "red !important",
                  },
                }}
              />
              <TextField
                className="text_field"
                label="Contact number/Email ID"
                name="contactEmailFriend"
                type="search"
                variant="standard"
                value={FormData.contactEmailFriend}
                onChange={(e) => handleChange(e)}
                // error={props.errors && props?.errors.name}
                // helperText={
                //   props?.errors.name && props?.errors.name
                //     ? "Please enter name"
                //     : " "
                // }
                // InputLabelProps={"Email"}
                style={{ marginTop: 10, width: 360 }}
                sx={{
                  "@media screen and (max-width: 400px)": {
                    width: "auto !important",
                    backgroundColor: "red !important",
                  },
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: 10,
              }}
            >
              <FormControl>
                <FormLabel
                  style={{
                    marginRight: 60,
                    display: "flex",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    color: "#193572",
                    fontFamily: styles.fonts,
                    marginTop: 15,
                  }}
                >
                  How did you hear about us ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="female"
                  name="hearFrom"
                  onChange={(e) => handleChange(e)}
                  defaultValue="FriendFamily"
                >
                  <FormControlLabel
                    style={{ marginTop: 10 }}
                    value="FriendFamily"
                    control={<Radio />}
                    label="Friends and Family."
                  />
                  <FormControlLabel
                    value="Facebook/Instagram/LinkedIn"
                    control={<Radio />}
                    label="Facebook/Instagram/LinkedIn"
                  />
                  <FormControlLabel
                    value="searchEngines"
                    control={<Radio />}
                    label="Search engines"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <Button
              style={{
                // display: "flex",
                paddingLeft: 20,
                paddingRight: 20,
                justifyContent: "center",
                marginTop: 14,
                backgroundColor: "#EEBB58",
              }}
              onClick={handleSubmitMain}
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
