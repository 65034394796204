import React from "react";
import Courses from "./Courses";
import { styles } from "../constant";
import "../style/Home.css";
import MenImg from "./../assets/men.png";
import RedirectiImg from "./../assets/redirection.png";
import axios from "axios";

import FormModal from "../component/Modal/FormModal";

import { useState } from "react";
import { TextareaAutosize } from "@material-ui/core";
import HomePageBack from "../assets/Homepage.jpg";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import MiniChatImg from "../assets/miniChat.png";
import CloseImg from "../assets/close.png";
import { Link } from "react-scroll";
import { getAPI, postAPI } from "../api";

export default function Home() {
  const [open, setOpen] = React.useState(false);
  const [HomePageData, setHomePageData] = React.useState();

  const [emailError, setEmailError] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [CommentError, setCommentError] = React.useState(false);

  const requestData = {
    url: process.env.REACT_APP_BASEURL_API + "V1/user_home/details",
  };

  React.useEffect(() => {
    getAPI(requestData)
      .then((res) => {
        setHomePageData(res?.data && res?.data[0]);
      })
      .catch((e) => console.log(e));
  }, []);

  const handleSubmit = (formData) => {
    // debugger;
    // console.log("all click", formData);
    // name: "",
    // email: "",
    // comment: "",
    // suggestion: "",
    // issues: "",
    // nameTwo: "",
    // contactEmail: "",
    // friendName: "",
    // contactEmailFriend: "",
    // hearFrom: "FriendFamily",

    var data = new FormData();
    data.append("name", formData?.name);
    data.append("email", formData?.email);
    data.append("comment", formData?.comment);
    data.append("que1_ans", formData?.suggestion);
    data.append("que2_ans", formData?.issues);
    data.append("que3_ans", formData?.nameTwo + "-" + formData?.contactEmail);
    data.append("que4_ans", formData?.contactEmail + "-" + formData?.contactEmailFriend);
    data.append("que5_ans", formData?.hearFrom);

    // console.log("form",data)

    if (formData.name === "") {
      setNameError(true);
      // console.log("errpr", FormErrors?.name);
    } else {
      setNameError(false);
    }
    if (formData.email === "") {
      setEmailError(true);
    } else {
      /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)
        ? setEmailError(false)
        : setEmailError(true);
    }

    if (formData.comment === "") {
      setCommentError(true);
    } else {
      setCommentError(false);
    }

    const requestData = {
      url: process.env.REACT_APP_BASEURL_API + "V1/feedback",
      data: data,
    };

    if (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.comment !== "" &&
      /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)
    ) {
      // postAPI(requestData)
      //   .then((res) => {
      //     // console.log("resData", res);
      //     // setBlogData(res?.data);
      //     setOpen(false);
      //     alert("Form Successfully Submitted");
      //   })
      //   .catch((e) => console.log(e));
    }
  };

  const style = {
    position: "absolute",
    padding: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 200,
    bgcolor: "white",
    display: "flex",
    justifyContent: "center",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // let titles = "Super Human Life and other's life ";

  // console.log(titles.length > 16 ? true : false);

  return (
    <div>
      {HomePageData && (
        <div
          style={{
            backgroundImage: `url(${HomePageBack})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="home-container" style={{paddingInline:70}}>
            <div
              className="sub_home_container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div>
                <h1
                  className="heading"
                  style={{
                    fontFamily: styles.fonts,
                    marginBottom: "0px",
                    width: "30px",
                  }}
                >
                  {HomePageData && HomePageData?.title?.length > 16
                    ? HomePageData.title.toUpperCase().slice(0, 16) + "..."
                    : HomePageData.title?.toUpperCase()}
                </h1>
                <p
                  className="sub-heading"
                  style={{
                    fontFamily: styles.fonts,
                    marginTop: "0px",
                    marginBottom: "40px",
                  }}
                >
                  {HomePageData && HomePageData?.sub_title?.length > 15
                    ? HomePageData?.sub_title?.slice(0, 15) + "..."
                    : HomePageData?.sub_title}
                </p>
                <p
                  className="desc-heading"
                  style={{
                    fontFamily: styles.fonts,
                    // marginBottom:"40px",

                    margin: "0px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      HomePageData && HomePageData?.description?.length > 129
                        ? HomePageData?.description?.slice(0, 128) + "..."
                        : HomePageData?.description,
                  }}
                >
                  {/* {HomePageData && HomePageData?.description} */}
                </p>
              </div>
            </div>

            <div
              className="home_img_div"
              style={{
                display: "flex",
                flexDirection: "Column",
                marginTop: "40px",
                // marginRight:"20px"
                // justifyContent: "center",
              }}
            >
              <img
                className="home-img"
                src={HomePageData?.image}
                alt="men"
                height={500}
                priority="true"
                style={{
                  // backgroundColor:"yellow",
                  width: "45vw",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <div className="down_arrow" style={{ cursor: "pointer" }}>
              <Link
                // activeClass="active"
                to="courses"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <img src={RedirectiImg} alt="men" height={35} width={35} />
              </Link>
            </div>
            {/* <div>
              <input type="checkbox" id="check" />
              <label className="chat-btn" onClick={() => setOpen(true)}>
                <img
                  src={Visible ? CloseImg : MiniChatImg}
                  alt="men"
                  height={!Visible ? 65 : 35}
                  width={!Visible ? 75 : 35}
                  style={{ marginTop: !Visible ? 10 : 3 }}
                />
              </label>
            </div> */}
          </div>
          {/* <FormModal
            handleClick={setOpen}
            value={open}
            onSubmitClick={(data) => handleSubmit(data)}
            errors={{
              name: nameError,
              email: emailError,
              comment: CommentError,
            }}
          /> */}

          {/* <div>
        <Courses />
      </div>
      <div style={{ padding: 120 }}>
        <Information />
      </div>
      <div style={{ padding: 120 }}>
        <BrandStory />
      </div>
      <div style={{ padding: 30 }}>
        <Knowledge />
      </div>
      <div>
        <Contact />
      </div> */}
        </div>
      )}
    </div>
  );
}
