import axios from "axios";

//common API Structure for get api

export function getAPI(props) {
  return fetch(props?.url, {
    method: "GET",
  })
    .then((res) => res.json())
    .then(
      (result) => {
        // console.log("Result", result);
        return result;
      },
      (error) => {
        error = error;
      }
    );
}

export async function postAPI(props) {

  return fetch(props?.url, {
    method: "POST",
    body: props?.data,
    "Content-Type": "multipart/form-data",
  })
    .then((res) => res.json())
    .then(
      (result) => {
        // console.log("Result", result);
        return result;
      },
      (error) => {
        error = error;
      }
    );
}
