import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import demo from "../../assets/demo.png";
import "../../style/courseCard.css";
import { styles } from "../../constant";
import { useNavigate } from "react-router-dom";
import badge from "../../assets/badge.png";
import { useState } from "react";

export default function CourseCard(props) {
  // console.log("course.Props", props);

  let CourseData = props?.product && props?.product;
  const [Error, setError] = useState(false);
  const navigate = useNavigate();

  let cardDesc = CourseData?.description.substring(0, 100);
  let cardTitle = CourseData?.title.substring(0, 45);
  // console.log("cardTitle",cardTitle)

  const ViewDetailHandler = () => {
    // debugger;


    let localNewsLetter = localStorage.getItem("newsLetterNotSubmitted");
    let popHandler = localStorage.getItem("popHandler");
    // console.log("popHandler", typeof popHandler + " " + popHandler)
    // console.log("localNewsLetter", typeof localNewsLetter + " " + localNewsLetter)
    // console.log("props?.eventHandle", typeof props?.eventHandle + " " + props?.eventHandle)
    if (localNewsLetter === "true" && popHandler === "false") {
      props.ViewDetailHandler(localNewsLetter, CourseData);
    }
    // console.log("props?.CourseData",CourseData?.chapter?.length)
    if (CourseData?.chapter.length > 0) {



      if (popHandler === "true") {
        navigate("course-detail", {
          state: { courseData: CourseData },
        });
      }
    } else {
      setTimeout(() => {
        setError(true);
      }, 1000);
      setError(false);
    }
  };

  const courseTag = CourseData?.tag?.split("For ")[1]
  // console.log("CourseData",CourseData)

  return (
    <Card
      onClick={ViewDetailHandler}
      sx={{
        // MaxWidth: 10,
        cursor: "pointer",
        width: 280,
        height: 470,
        boxShadow: 5,
        borderRadius: 3,
        marginInline: 3,
        marginBottom: 5,
        backgroundColor: "rgba(255, 251, 243, 1)",

        // display:"flex",
        // flexDirection:"column",
        // justifyContent:"space-between",
        "@media screen and (min-width: 1024px) and (max-width: 1350px)": {
          maxWidth: 250,
          // backgroundColor:"red"
        },
        "@media screen and (min-width: 768px) and (max-width: 1023px)": {
          maxWidth: 260,
          // backgroundColor:"red",
          marginLeft: 3,

        },

        "@media screen and (min-width: 425px) and (max-width: 767px)": {
          maxWidth: 280,
          minHeight: 485,
          marginLeft: 3.2,
          height: "auto",
          // height:120,
          width: 300
          // backgroundColor:"red"
        },
        "@media screen and (min-width: 375px) and (max-width: 425px)": {
          maxWidth: 250,
          // marginLeft:7,
          // backgroundColor:"aqua"
        },
        "@media screen and (min-width: 352px) and (max-width: 376px)": {
          maxWidth: 260,
          marginLeft: 3
          // width:335,
          // backgroundColor:"aqua"
        },
        "@media screen and (min-width: 0px) and (max-width: 352px)": {
          maxWidth: 230,
          width: 260,
          // backgroundColor:"aqua"
        },
      }}
    >
      <div className="badge">
        {/* <img className="badge_img" src={badge} width={100} alt="badge" />
        <span>FREE</span> */}

        <div className="head-text">
          <div className="head-image">
            <img className="badge_img" src={badge} width={100} alt="badge" />
          </div>
          <div
            className="text-on-image"
            style={{ color: "white", fontStyle: styles.fonts }}
          >
            <p
              style={{ color: "white", fontStyle: "Inter", fontWeight: "bold" }}
            >
              {CourseData?.is_lock?.toUpperCase()}
            </p>
          </div>
        </div>
      </div>
      <CardMedia component="img" alt="green iguana" height="210" image={CourseData?.image} />
      <CardContent
        sx={{
          minHeight: 120,
          height: "auto",
          textAlign: "left",
          // backgroundColor:"rgba(255, 251, 243, 1)",
          "@media screen and (max-width: 1024px)": {
            minHeight: 120,
            height: "auto",
          },
        }}
        style={{ paddingTop: 0 }}
      >
        {/* {console.log(CourseData?.tag)} */}
        <p className="tip">For <b>{courseTag}</b></p>
        <Typography
          gutterBottom
          // variant="h5" 
          component="div"
          className="card_header"
          style={{
            fontFamily: styles.fonts,
            fontWeight: "bold",
            fontSize: "1.3rem",
            lineHeight: "25px",
            wordWrap: "break-word",
          }}
        >
          {cardTitle.length >= 42 ? `${cardTitle}...` : cardTitle}
          {/* {console.log(cardTitle.length)} */}
        </Typography>
        <Typography
          variant="body2"
          className="card_subheader"
          style={{ fontFamily: styles.fonts }}
        //   height={"100%"}
        >
          {cardDesc.length >= 20 ? `${cardDesc.slice(0, 80)}...` : cardDesc}
        </Typography>
      </CardContent>
      <CardActions style={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="outlined"
          className="card_viewBtn"
          onClick={ViewDetailHandler}
          style={{
            borderColor: "#193572",
            color: "#193572",
            fontWeight: 700,
            // marginLeft: 10,
            // marginBottom: 15,
            // width:"9vw"
          }}
        >
          View Details
        </Button>
        {Error && (
          <div style={{ marginTop: 5 }}>
            <span color="red" style={{ color: "red" }}>
              Sorry ,chapter data is not available
            </span>
          </div>
        )}
      </CardActions>
    </Card>
  );
}
