import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Dialog } from "@material-ui/core";
import { styles } from "../../constant";
import RedirectiImg from "./../../assets/redirectSecond.png";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import { Dailog } from "@material-ui/core";
import ScrollBars from "react-scrollbar";
import "../../style/Blog.css";
import BlogDialog from "../Popup/BlogDialog";

// const styles = (theme) => ({
//   root: {
//     maxWidth: "1300px", // arbitrary value
//     maxHeight: "1300px",
//     padding: "0px",
//     borderRadius: "34px",
//   },
// });

const useStyles = makeStyles({
  root: {
    maxWidth: "1700px", // arbitrary value
    maxHeight: "1900px",
    padding: "0px",
    width: "auto",
    borderRadius: "34px",
  },
});

export default function MediaCard(props) {
  // console.log("props", props);
  const classes = useStyles();

  const [PopupOn, setPopupOn] = useState(false);
  const [PopupData, setPopupData] = useState(props?.dataProps);

  const scrollBarStyle = {
    // border: "1px solid red",
    // width: "300px",
    height: "124px",
  };
  const popscrollBarStyle = {
    // border: "1px solid red",
    // width: "300px",
    height: "20vh",
  };
  // const popscrollBarStyleSize = {
  //   // border: "1px solid red",
  //   // width: "300px",
  //   height: "20vh",
  // };

  const handleClose = () => {
    setPopupOn(false);
  };

  // const handleClose = (event, reason) => {

  //   setPopupOn(false)
  // };

  const handleCloseForm = () => {
    handleClose();
  };

  useEffect(() => {
    setPopupData(props?.dataProps);
  }, []);

  // console.log("PopupData", PopupData);
  // const styles = {
  //   customMaxWidth: {
  //     maxWidth: "1300px", // arbitrary value
  //     maxHeight: "1300px",
  //     padding: "0px",
  //     borderRadius: "34px",
  //   },
  // };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(0),
    },
  }));

  // console.log("PopupOn", PopupOn);
  return (
    <Card
      sx={{
        maxWidth: props?.dataProps?.width,
        width: "100%",
        borderRadius: 8,
        cursor: "pointer",
        marginInline: "auto",
        boxShadow: "0px 0px 25px rgb(112 144 176 / 80%)",

        // backgroundColor:"red"
      }}
      onClick={() => setPopupOn(!PopupOn)}
    >
      <div
        className="blog_card"
        style={{
          display: "flex",
          // backgroundColor:"red",
          flexDirection: props?.dataProps?.id === 1 ? "column" : "row",
        }}
      >
        <div
          style={{
            minWidth:
              props?.dataProps?.id === 1 ? props?.dataProps?.width : 250,
            // cursor:"crosshair"
          }}
        >
          <CardMedia
            className="blog_img"
            component="img"
            height={props?.dataProps?.height}
            // width={ }
            image={props?.dataProps?.img}
            alt="green iguana"
          // style={
          //   {
          //     // height:"200px"
          //   }
          // }
          />
        </div>
        <div className="card_content">
          <CardContent>
            {props?.dataProps?.id !== 1 ? (
              <p className="tip" style={{ fontFamily: styles.fonts }}>
                {props?.dataProps?.time}
              </p>
            ) : null}
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="blog_card_subheader"
            >
              {props?.dataProps?.id === 1 ? (
                <p className="tip" style={{ fontFamily: styles.fonts }}>
                  {props?.dataProps?.time}
                </p>
              ) : null}
              <span
                style={{
                  fontFamily: styles.fonts,
                  fontWeight: 900,
                  color: "#193572",
                  lineHeight: "1px",
                  // fontSize:"24px"
                }}
              >
                {/* {props?.dataProps?.heading} */}
                {props?.dataProps?.heading.slice(0, 45)}
              </span>
            </Typography>

            {/* <ScrollBars horizontal autoHide={false} style={scrollBarStyle}> */}
            <Typography
              variant="body2"
              color="#393939"
              style={{ marginRight: "10px" }}
            >
              <span style={{ fontFamily: styles.fonts }}>
                {props?.dataProps?.subHeader.length > 250
                  ? props?.dataProps?.subHeader.slice(0, 120) + "..."
                  : props?.dataProps?.subHeader}
                
              </span>
            </Typography>
            {/* </ScrollBars> */}
          </CardContent>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              // paddingTop: 40,

              paddingLeft: 17,
              paddingRight: 17,
              paddingBottom: 20,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontFamily: styles.fonts,
                  fontSize: 13,
                  // height:10,
                  display: "flex",
                  alignItems: "center",
                  color: "#393939",
                  fontWeight: 300,
                }}
              >
                {props?.dataProps?.publish}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={RedirectiImg} alt="men" height={35} width={35} />
            </div>
          </div>
        </div>
        <BlogDialog
          open={PopupOn}
          handleClose={handleClose}
          blogData={props?.dataProps}
        />
      </div>
    </Card>
  );
}
