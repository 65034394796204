import React from "react";
import "../style/CourseDetails.css";
import { styles } from "../constant";
import { Chip } from "@mui/material";
import Contact from "./Contact";
import CourseVideo from "../component/Courses/CourseVideo";
import bg from "../assets/bg.png";
import { useLocation } from "react-router-dom";

const CourseDetails = () => {
  const { state } = useLocation();
  let courseData = state?.courseData;
  // console.log("state", state?.courseData);
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // console.log("courseData.chapter < 0", courseData)

  const courseTag = courseData && courseData?.tag?.split("For ")[1]

  // const { id, color } = state; // Read values passed on state
  return (
    <>
      {courseData?.chapter?.length > 0 ? (
        <div>
          <div
            className="course_details"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundColor: "#F4F7FB",
              backgroundRepeat: "no-repeat",
              // backgroundSize:
              backgroundSize: "cover",
            }}
          >
            <div className="course_details_title">
              <div className="course_title" style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <h1
                    className="course_header"
                    style={{
                      // fontSize: "50px",
                      fontFamily: styles.fonts,
                      // margin: "0px",
                    }}
                  >
                    {courseData && courseData?.title}
                  </h1>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p className="tip">For <b>{courseTag}</b></p>
                  </div>
                </div>
                {/* <Chip size="small" label="For 8yrs+" className="course_tag" /> */}
              </div>
              <p
                style={{
                  fontFamily: styles.fonts,
                }}
                className="sub_header"
              >
                {courseData && courseData?.description}
              </p>
            </div>
            <hr />

            <CourseVideo courseData={courseData && courseData} />
          </div>
          <Contact />
        </div>
      ) : (
        <div
          className="course_details"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundColor: "#F4F7FB",
            // backgroundSize:"cover",
            textAlign: 'center'
          }}
        >
          {" "}
          <h1>Data not found</h1>
        </div>
      )}
    </>
  );
};

export default CourseDetails;
