import React from "react";
import { styles } from "../constant";
import { useState, useEffect } from "react";
import "./../style/brandSrory.css";
import { Box } from "@mui/material";
import MissionImg from "./../assets/mission.png";
import ValueImg from "./../assets/value.png";
import VisionImg from "../assets/vision.png";
import { getAPI } from "../api";
import ScrollBars from "react-scrollbar";
import Ellipseblue from "../assets/Ellipseblue.png";
import Ellipseyellow from "../assets/Ellipseyellow.png";
import Ellipsegreen from "../assets/Ellipsegreen.png";
import Ellipsered from "../assets/Ellipsered.png";

export default function BrandStory() {
  const [BrandData, setBrandData] = useState();

  const requestData = {
    url: process.env.REACT_APP_BASEURL_API + "V1/brand/details",
  };

  React.useEffect(() => {
    getAPI(requestData)
      .then((res) => {
        // console.log("resData", res);
        setBrandData(res?.data[0]);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div id="BrandStory">
      {BrandData && (
        <div
          className="brand_container"
          style={
            {
              // width: "100",
              // display: "flex",
              // // backgroundColor: "red",
              // justifyContent: "space-between",
            }
          }
        >
          <div className="side1">
            <div
              className="brand_header"
              style={{
                fontFamily: styles.fonts,
              }}
            >
              {BrandData && BrandData?.title}
            </div>

            <ScrollBars
              // className="scroll_container"
              horizontal
              autoHide={false}
              style={{ height: "auto" }}
              // sx={{
              //   "@media screen and (max-width: 768px)": {
              //     border:"1px solid red" ,
              //     height: "20vh",

              //   },
              // }}
            >
              <div
                className="brand_desc"
                style={{
                  fontFamily: styles.fonts,
                  // backgroundColor:"red",
                  // width: "80%",
                  // textAlign: "justify",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: BrandData && BrandData?.description,
                  }}
                />
              </div>
            </ScrollBars>
          </div>
          <div className="side2">
            <div className="box box1">
              <figure>
                <img className="ellipseblue" src={Ellipseblue} alt="blue" />
              </figure>
              <figure>
                <img className="ellipsered" src={Ellipsered} alt="blue" />
              </figure>
              <figure>
                <img
                  className="ellipsegreensmall"
                  src={Ellipsegreen}
                  alt="blue"
                />
              </figure>
              <figure>
                <figure>
                  <img
                    className="ellipsebluesmall"
                    src={Ellipseblue}
                    alt="blue"
                  />
                </figure>
                <img className="ellipseyellow" src={Ellipseyellow} alt="blue" />
              </figure>
              <figure>
                <img className="ellipsegreen" src={Ellipsegreen} alt="blue" />
              </figure>
              <Box
                sx={{
                  width: "20vw",
                  height: "fit-content",
                  borderRadius: 5,
                  justifyContent: "inherit",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 0px 25px rgba(112, 144, 176, 0.8)",
                  padding: "10px 10px 10px 10px",
                  "@media screen and (min-width: 768px) and (max-width: 1024px)":
                    {
                      padding: "6px 6px 6px 6px",
                      width: "20vw",

                      // padding: "20px 0px 0 0px",
                    },
                  "@media screen and (max-width: 768px)": {
                    width: "82vw !important",
                  },
                  "@media screen and (max-width: 767px)": {
                    width: "90vw !important",
                  },
                  "@media screen and (max-width: 475px)": {
                    width: "75vw !important",
                  },
                }}
              >
                {/* <
            style={{ display: "flex", flexDirection: "column", padding: 10,backgroundColor: "green",  }}
          > */}
                <div className="brand_Card">
                  <img
                    className="brand_Card_img"
                    src={VisionImg}
                    alt="img"
                    height={50}
                    width={50}
                  />
                </div>

                <div
                  className="brand_boxText"
                  style={{ fontFamily: styles.fonts }}
                >
                  Our Vision{" "}
                </div>
                <div
                  className="brand_boxPara"
                  style={{ fontFamily: styles.fonts }}
                >
                  Carving paths for a healthier and wealthier life
                </div>
              </Box>
            </div>

            <div className="box box2">
              <Box
                sx={{
                  // width: 300,
                  width: "20vw",
                  height: "fit-content",
                  borderRadius: 5,
                  justifyContent: "inherit",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 0px 25px rgba(112, 144, 176, 0.8)",
                  padding: "10px 10px 10px 10px",
                  "@media screen and (min-width: 768px) and (max-width: 1024px)":
                    {
                      padding: "6px 6px 6px 6px",
                      width: "20vw",
                    },
                  "@media screen and (max-width: 768px)": {
                    width: "82vw !important",
                  },
                  "@media screen and (max-width: 767px)": {
                    width: "90vw !important",
                  },
                  "@media screen and (max-width: 475px)": {
                    width: "75vw !important",
                  },
                }}
              >
                {/* <
            style={{ display: "flex", flexDirection: "column", padding: 10,backgroundColor: "green",  }}
          > */}
                <div className="brand_Card">
                  <img
                    className="brand_Card_img"
                    src={MissionImg}
                    alt="img"
                    height={50}
                    width={50}
                  />
                </div>

                <div
                  className="brand_boxText"
                  style={{ fontFamily: styles.fonts }}
                >
                  Our Mission
                </div>
                <div
                  className="brand_boxPara"
                  style={{ fontFamily: styles.fonts, wordWrap: "break-all" }}
                >
                  To communicate quintessential life skills through interactive,
                  holistic exercises and providing a platform for complete
                  growth of an individual, and through that the growth of our
                  society
                </div>
              </Box>
            </div>

            <div className="box box3">
              <Box
                sx={{
                  // width: 300,
                  width: "20vw",
                  height: "fit-content",
                  borderRadius: 5,
                  justifyContent: "inherit",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 0px 25px rgba(112, 144, 176, 0.8)",
                  padding: "10px 10px 10px 10px",
                  "@media screen and (max-width: 768px)": {
                    width: "82vw !important",
                  },
                  "@media screen and (max-width: 767px)": {
                    width: "90vw !important",
                  },
                  "@media screen and (max-width: 475px)": {
                    width: "75vw !important",
                  },

                  // marginTop: 2,
                }}
              >
                {/* <
            style={{ display: "flex", flexDirection: "column", padding: 10,backgroundColor: "green",  }}
          > */}
                <div className="brand_Card">
                  <img
                    className="brand_Card_img"
                    src={ValueImg}
                    alt="img"
                    height={50}
                    width={50}
                  />
                </div>

                <div
                  className="brand_boxText"
                  style={{ fontFamily: styles.fonts }}
                >
                  Our Values
                </div>
                <div
                  className="brand_boxPara"
                  style={{ fontFamily: styles.fonts }}
                >
                  Commitment and perseverance to improve. Foster diversity,
                  equality and embracing change
                </div>
              </Box>
            </div>
          </div>
          {/* <div style={{ padding: 50, marginTop: 50 }} id="story"></div> */}
        </div>
      )}
    </div>
  );
}

/*

<div style={{ backgroundColor:"yellow", width:'fit-content'}}>
<div>
  <h1
    className="brand_header"
    style={{
      display: "flex",
      // justifyContent: "center",
      fontFamily: styles.fonts,
    }}
  >
    Our Brand Story
  </h1>
</div>
<div
  style={{
    display: "flex",

    // justifyContent: "center",
    fontFamily: styles.fonts,
  }}
>
  <p style={{ width: "70%" }}>
    Schools are doing a terrific job with the resources they have.
    However, they do not adequately prepare the young minds for the
    challenges they encounter in everyday life, yet. Throughout our
    childhood, we aren’t actually taught how to make and manage money,
    learn to think clearly, build relationships, take care of our mental
    and physical health. The lack of this education stops the children
    from unleashing their true potential and grow into fulfilled and
    happy adults. Parents are counted on to teach these skills to their
    children, but times have changed. <br />
    <br />
    Parents are in a tough spot themselves, being a full-time parent,
    juggling work and personal goals, we understand they need a helping
    hand too and empathise with their situation. <br />
    <br />
    <p className="brand_subPara">
      Unition Academy is here to provide all the skills and knowledge
      necessary for your children to grow into
      <span style={{ color: "#ED7070" }}>“SuperHumans”</span> and act as
      a support system for the parents with all their parenting needs.
    </p>
  </p>
</div>
</div>
<div>
<div style={{ flex:1 ,  backgroundColor:"red" }}>
  <Box
    sx={{
      width: 300,
      height: 200,
      justifyContent: "flex-end",
      borderRadius: 10,
      backgroundColor: "#95BFD7",
      marginTop: 0,
    }}
  >
    <div
      style={{ display: "flex", flexDirection: "column", padding: 10 }}
    >
      <div className="brand_Card">
        <img src={VisionImg} alt="img" height={80} width={80} />
      </div>

      <div className="brand_boxText">
        <p style={{ fontFamily: styles.fonts }}>Our Vision</p>
      </div>
      <div className="brand_boxPara">
        <p style={{ fontFamily: styles.fonts }}>
          Carving paths for a healthier and wealthier life
        </p>
      </div>
    </div>
  </Box>
  <Box
    sx={{
      width: 300,
      height: 250,
      borderRadius: 10,
      justifyContent: "flex-start",
      backgroundColor: "#95BFD7",
      marginTop: 2,
    }}
  >
    {" "}
    <div
      style={{ display: "flex", flexDirection: "column", padding: 10 }}
    >
      <div className="brand_Card">
        <img src={MissionImg} alt="img" height={80} width={80} />
      </div>

      <div className="brand_boxText">
        <p style={{ fontFamily: styles.fonts }}>Our Mission</p>
      </div>
      <div className="brand_boxPara">
        <p style={{ fontFamily: styles.fonts }}>
          To communicate quintessential life skills through interactive,
          holistic exercises and providing a platform for complete
          growth of an individual, and through that the growth of our
          society
        </p>
      </div>
    </div>
  </Box>
  <Box
    sx={{
      width: 300,
      height: 200,
      borderRadius: 10,
      justifyContent: "inherit",
      backgroundColor: "#95BFD7",
      marginTop: 2,
    }}
  >
    <div
      style={{ display: "flex", flexDirection: "column", padding: 10 }}
    >
      <div className="brand_Card">
        <img src={ValueImg} alt="img" height={80} width={80} />
      </div>

      <div className="brand_boxText">
        <p style={{ fontFamily: styles.fonts }}>Our Values</p>
      </div>
      <div className="brand_boxPara">
        <p style={{ fontFamily: styles.fonts }}>
          Commitment and perseverance to improve. Foster diversity,
          equality and embracing change
        </p>
      </div>
    </div>
  </Box>
</div>
</div>

*/
